import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {useLocation} from '@reach/router';
import {graphql, useStaticQuery} from 'gatsby';

const Head = ({title, description, image}) => {
    const {pathname} = useLocation();

    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            siteUrl
            defaultImage: image
          }
        }
      }
    `,
    );

    const {
        defaultTitle,
        defaultDescription,
        siteUrl,
        defaultImage,
    } = site.siteMetadata;

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname}`,
    };

    return (
        <Helmet title={title} defaultTitle={seo.title} titleTemplate={`%s | ${defaultTitle}`}>
            <html lang="en"/>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/devicons/devicon@v2.12.0/devicon.min.css"/>
            <meta name="description" content={seo.description}/>
            <meta name="image" content={seo.image}/>

            <meta property="og:title" content={seo.title}/>
            <meta property="og:description" content={seo.description}/>
            <meta property="og:image" content={seo.image}/>
            <meta property="og:url" content={seo.url}/>
            <meta property="og:type" content="website"/>
        </Helmet>
    );
};

export default Head;

Head.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
};

Head.defaultProps = {
    title: null,
    description: null,
    image: null,
};
