const MyColours = {
    'PageBackground': ['purple.50', 'gray.800'],
    'CardBackground': ["white", "cyan.800"],

    'NavFooter': ["white", "gray.800"],

    'Header': ["purple.700", "green.100"],
    'TextBlock': ["gray.600", "blue.200"],
    'SubTextBlock': ["gray.500", "blue.100"],

    'NavigationNumber': ["purple.600", "green.300"],
    'NavigationText': ["gray.400", "blue.200"],
    'NavigationTextHover': ["purple.600", "green.300"],

    'colorScheme': ["purple", "green"]
}
export default MyColours